import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/web2/gatsby-starter-blog/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="col-lg-8 my-5">
      <h2>{`Summary`}</h2>
      <p>{`Esteban was in charge of product concept, product management and product launch.`}</p>
    </div>
    <div className="col-lg-8 my-5 py-5 border-top">
      <h2>{`1. Research and Strategy`}</h2>
      <p>{`Analyzing the market conditions of Germany and the trends within European investment showed movement within digital healthcare. In this space, there's three types of startups: those that heal (outcome focused), those that support therapists (medically focused), those that change processes (technological focus).`}</p>
      <p>{`A large underserved market was found amid physiotherapy professionals. Whose work is highly stressful and increasing documentation demands interrupt their day to day.  `}</p>
      <p>{`An opportunity to create a solution that simplifies this process via wearables and software integration was conceived.  To develop the solution we spoke with therapists in different cities of Germany, Spain and India. `}</p>
    </div>
    <div className="row">
      <div className="col-lg-3 offset-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/c9aa2bdf70bef989f644587de174cd13/df56e/pendulum_10.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB60lEQVQ4y8WUy27TQBSG/Xi8AFvWPAASUZ2MkzRq01ybWlAWvEM3iB2IXVShSqU0KbWDUGhw6svElzi2ZzxnDkqKoK0igZoFv/7FHOn/NEdHOkfBDaRsBAMAe5AAYLOfTXNEiFarVUmlVt3eKRFS0TRNKxOilUi5qKpbqtrY292uloukUiyWCCHl6s6WSkamqQzPT9t1tdnqNveq7Xqh09V1Xe/1DnoHerez3253O539w8OXuq43mu3dRr3V6r5qPS0/f3L6aaBEIWUszjgiCpTwb/3GnEe+Hyo+tW3X+T6dpWmapGyRpEmSCiHyPEfE/mfrbf8bomScizznnC+SJMkgijPP85TZjM7CQAIwzjO2NGOrNkAiInl9/KjwBhFBLksAWAY4jxcLj1LF9/0wmidZthY+enf5+NnRCrsN5/Ei+QUHYWR77g15D37/cVp4cfK7XAO7lFqTq4yxW/Ay6lxbxyeDD/1zazIWAGvhwHVsn3p3YRSAl1/HZ8OLwcWXs6HBV/O7DwdB4E9tHkUgJc/FystcDlKAFIhCIqyMiFLKXIAAmTFGKVU8172aTH9c06lljcdjz/McxwmCYD6fz6Mw+uM7sh3bdV0ljmPTNEejkWEYN4+/yjRNwzDiON5sMeSDBABSSuW/XZKfpYFTNIDmqNsAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "pendulum",
                "title": "pendulum",
                "src": "/static/c9aa2bdf70bef989f644587de174cd13/5a190/pendulum_10.png",
                "srcSet": ["/static/c9aa2bdf70bef989f644587de174cd13/772e8/pendulum_10.png 200w", "/static/c9aa2bdf70bef989f644587de174cd13/e17e5/pendulum_10.png 400w", "/static/c9aa2bdf70bef989f644587de174cd13/5a190/pendulum_10.png 800w", "/static/c9aa2bdf70bef989f644587de174cd13/df56e/pendulum_10.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/19921d9bbf8e87ed52d1b09ff7317d80/df56e/pendulum_6.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADM0lEQVQ4y6WUW2vcRhiG9TsKcZx413FtmotCSulFoBelf6AXve/PyH1z0UIh3aPtUqidpobYblg3IRg7Dklj13Ftab0naaXVaVd70Gnmm5E0Unbrok0Ipbd+GJh3vnlfmPkYhptcAu7iEnD5crGQz+fyhVJ5NRWFfHG5nM/lcoVSqZSKQu5esZh6flopFQrFleXygwf3f1lbK5fL3PzNxaWlpeyNhcWbH89nM7PZuYWPFjNzmdkbS9czmSszV2eyC9fmsjNXPlj68NrM1dn5+eu3P7v15ee3b336CWfqhmGYpmHoumaYpq7rkiTKSlvviJIst89P2jv32sKRohodVdM1vaNqHaVjGnq31+Mi9p4oYlHIwiR+E0X/dO1xFE9Cx/QOSsFQZck4ZiFjqT2O47cBDiGEpxAICUQBSWyn9bz1dPWwoo9USiPEJhgwxghDiCFEyMc4DSGMOYwRAEHY7XT3RaNS17Yk40ldP25ax0PPokAx9oG+wRj5tuKNFAwRhgAAAwBHIQQAAsSxlWfN9Yd/fyforxJ6ERI2TWJKCIxOidt2Gned2h1s7QbYRogAAs60FUpI19Usr9d1TNdDw9EQYYSw76d3QQMUqPJq11b0znq7+aNU+96y5f02PdJ8blfdDyh7rT7fE38/7OwORhZOj0RiGmxL8qZs/Fzv/9AkZdFbVsINheyo5LHmbcjuIx1zX501Op7vucMn9Y1KbW1P2j7s7D6srliOQUm42R7mq71t1d0SrXXJH3gegQBj+rbN3Bcnwlaz8rh2v9ETkQd/ys9449Toa45nU0IJEEibQjFQZLcAALwWIAuApg3TbafnWIOBfFArqYPGifJHTdkBFzsjr9E7PdJe0P6eP3gF4Fnnd7DbdrRf/eEx0BgAcwEgSseod6D89bUzrPfV35TX3/iuDhCO3P7AcwfK5sh86fdf2OK3fq+CYJIeGaVwUZQ+mogFCQvimMWMJSxICzFL4ngyviD2y8AX4njCIspYlHriOEmS8XjM8TwvCILA8zx/xk+nVEyL0+WZUG0K1bognKWFdwa+Wj2v1eqc9A5xOv4r3tOUpNb/tsQpl/sMLhP+FxCE/7W7FCOOAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "pendulum",
                "title": "pendulum",
                "src": "/static/19921d9bbf8e87ed52d1b09ff7317d80/5a190/pendulum_6.png",
                "srcSet": ["/static/19921d9bbf8e87ed52d1b09ff7317d80/772e8/pendulum_6.png 200w", "/static/19921d9bbf8e87ed52d1b09ff7317d80/e17e5/pendulum_6.png 400w", "/static/19921d9bbf8e87ed52d1b09ff7317d80/5a190/pendulum_6.png 800w", "/static/19921d9bbf8e87ed52d1b09ff7317d80/df56e/pendulum_6.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/0aaa1ab0aee77784c3d4c3d59401da71/df56e/pendulum_9.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC3ElEQVQ4y8WU2U8TURSH5w/xzfhAfBUDgoBQdmUttKVLWMpiiyxRLMy0Q1uBUhYLTEtThGCCAVGMikQIPmiivpiYYBQemEpElEhbZjoLyxTuvQYQfYcHv5zc/HKSL+flnIuhU4CdSgYASCcCAHC6yTTtt1gsNhtJWu1We5vZQpoJnCRJwmw+yGYLjuOtpNnR6cQJEm/BCYJotbXjBEnTNLb0ZYG6ax+gPJ1OZ4ejs69vwE15Bge9bs+g231QFOX2er0+n6+7p9fZ3eVyUb7eGqtJt/BpERN59vvqkt9PQ7CHEJIkaWt7a3d3Z2tblCI7krSDEAJgH0KIEIII7u8DhPhIRGBZDhM4trsfrzVqA4EAgMjhsJkstxtvGo0NZfo6VWWFqtVhM96qono73r6cdnkodZn23YePkT0UCAQwjuOfzQ93+AwhJggRajG3VBkq9QZ1ZZ1GUX5VUSbTVRdrrqtMVUW9FfnKoszU9ISZ2WmEDmWBFydnfAOjzWGOhQiZmpvyCrL0N5SaitwCZbZcm16okMkyZXqNzlFbk52anJ4aN/Xk4T955JHTSpWzHAMh6nI1y3XJZcYsuSbmWvGlYnWcoiRWnpIyNj7zZv59TnK2MjtxbmYSHsvC+LTH4W08kl1D5tL6jNK6DGV1fIH6grwwWq+SKZJkQ8MTo6OThQlplYVZr189B/BQ5nlhYtrb42tiORYA1O5qyNPFFuguJ2ZFxcSeUWvyc9KuXDx3XnNVnhQdH3c2qio3b27uxR9ZFLZGHjtt/XpOCCOE7j3oqjWVGBq0hsbS2vqK7nYL2WBUlOgIwq7TlBpUWt+dtsWlzxCiUCiEbW4yT2fH7k+51n/9DAaDoVCIZRmGYTiOZ9gww2wKPBcOh3ekiLQHwjzPMKzACxuBjWAwiImiuPpt7cfa+tdDVo75m4+Cn6b9NL1y2KNpenl5WRRF7GhvDp4THAY8EQAACCH2336S39BNsIS2gKnWAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "pendulum",
                "title": "pendulum",
                "src": "/static/0aaa1ab0aee77784c3d4c3d59401da71/5a190/pendulum_9.png",
                "srcSet": ["/static/0aaa1ab0aee77784c3d4c3d59401da71/772e8/pendulum_9.png 200w", "/static/0aaa1ab0aee77784c3d4c3d59401da71/e17e5/pendulum_9.png 400w", "/static/0aaa1ab0aee77784c3d4c3d59401da71/5a190/pendulum_9.png 800w", "/static/0aaa1ab0aee77784c3d4c3d59401da71/df56e/pendulum_9.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <div className="col-lg-8 my-5 py-5 border-top">
      <h3>{`2. Product Design`}</h3>
      <p>{`Along with a team comprised of post-doc in Computer Science and a Biomedical Engineer we developed various products building parts of a larger solution. `}</p>
      <p>{`Esteban held ongoing user interviews, designed product concepts and prepared startup approaches for 4 different potential products. `}</p>
    </div>
    <div className="row">
      <div className="col-lg-3 offset-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/ebf5bc70ed149db6d1a489417800b943/df56e/pendulum_1.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADO0lEQVQ4yy2T2W7kVgxE9f/PyQQTIIsTx2P3opbuvpB3X6TbspFPCtoOUKg3og5Z4IROS/p6Hzlnv4267XUfbdzb/ehfGve2j/ql949tH7X11FpqvUxKM3r7s1XH2SoEIysRQlhrjFFcMETQWjkHOYeUgwUNCLmklHypfhJikexl7AVA9a2MfYsxAALndF0X511KIee4bX3b2vU6K6VbqynFlPzE2az4j49/R4hQW0YEpeTpfJ7nGcD23tpWS81jbL3X1qr3obX6FT6R9WTk2/3YEO22tVqzNtpYE3POJb+/3/tWYwpccEQbo0eE1krO0XucbvMLmMu2VwC9733fu5TCGHUc437s92P/DCzOuxC9sUYblXNMKQCY6XL+K+DaWrZGtVbG6MuyXC6X4xhjbMcxeq+fOJZLaSwIJb133qPWcnr98XuKvLUEVpeSeq9CcELWMfZ97/f7w9HhShkTknHB5WM4BIdop5fnX0vWrReHttacUigllZZLzbWXvtXe6//3l1JpI5RywacUHju/PH/vzdZWpOTGKGs0OvDRxxR89C46dOgcOo9Ka2U0l8IFF2JAB9Pz07dWLaIlZGGM3JYb5YxLAQ6U0dqaL7ng0KOxhjBqEcCBRTs9P/3cGijFKV2U4itdmGToET7zQwoWLXpMJaYSV0pO57NFa8BKrabnp5/GCCGiEIyxlbL1Ol9KTX1v2+h9b7WXkHztpfYSczRgtTUWQRs1vfz97T6iBaW1yCVIJYRk6Cz6R3LtZRsdPRgwQgltlTKKCWbRPqr68fztODKgZnS1VhornbOlJnDWhQd8Lgk9MMEJo5RTpZXU0lgjJZ9e//nl/aMBakIWbeTnu4TaEnosLcccfHRciljiF7lFIIwyyTln0+n1+/t7M1YQcuOCKM0ZWx8ggoIDoYQL+OU+OgxowBBGuBRC8Ol2/u1+NKUYpeuy3AC0NtIHlFq64CyakLy2KiQfkn9MUjovC+OMcz4t1z/2UShb1vW2rjepOGEL+gebAcMlcwHJowbyeno7Xy/n6/ntdKKcMk4nMj+1nubbWUhqjEQ0AEZbJZTUVkstKWeX+bqy9Xq7LmRZyDrfZi44Jet/PQNv1EvdEbsAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "pendulum",
                "title": "pendulum",
                "src": "/static/ebf5bc70ed149db6d1a489417800b943/5a190/pendulum_1.png",
                "srcSet": ["/static/ebf5bc70ed149db6d1a489417800b943/772e8/pendulum_1.png 200w", "/static/ebf5bc70ed149db6d1a489417800b943/e17e5/pendulum_1.png 400w", "/static/ebf5bc70ed149db6d1a489417800b943/5a190/pendulum_1.png 800w", "/static/ebf5bc70ed149db6d1a489417800b943/df56e/pendulum_1.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/1cefb588d2d23c400454c8695c99d304/df56e/pendulum_5.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACdElEQVQ4y6VUa0/bMBTt//8xe3USaAwGtB0rZRT6rtI2Ic374biO7fgRO1Oa0knAF8SRZenaPr7Hx75uaV0dWvVutKoPoBWkaGqFMyv0kt3raa0rpfShab0PG9Rhyw7B7/Gm+2BYAXh35gigh+XT/cL0YiiFkFIKIcpS6b0HmLLxyp1uvPlT5CaoYGyy2g4X1mjlGE7SsoLsYjD72Z8s7ZAVBWMMYxyFQbM3zOnFYH45mPbGpuGmmJDz/vi0Oxwst3+XTm3YDtM0Q80J973yoowL0fA5Z0EMGBdHH7ZeQApWy964Sbs3+dYZr5xkt4Mgg0PD/9IZ38y2CYAIoYkZfO2Mfw3XfpxRgmcbt92dnNzMTTdu+SnsjMzrx40TAc5ZjsnMCn8MjId1gDDhnBtOcnZn/JnaGSKCcyeCvalzM3NCgGrZmBRpBo+yq6pCmEoptFZSylKKFCJMqCprL1UpAUQQ5aqUNVkIked5cx5CmR8Dc+uZWz8GUAjBOZd7iGeUz2FNLkuV5zkhhBWFYXmn/fnn89vvvcncOniulGKMMX5AswXn/OXzVEqVSvlhxIVUSh8Nl0JUWgnBVSkpJUVBlSpbr96jcmN4v45MPyOECiH9FC3W9nKzDQFWSsOcjqxkaqc5Yf/JTRZMiuvHzend+uxuFcQgTUF/4bavh+2r4WDpApCN1u7JYHVyayws/42qwoQuLY8ysRdSoygo51zvL0MrZbpRlOGq0m+QhRC245WlashVVQGIMoSPYRDGmNCX9dwo35Hi09XIibLjzXdHVn9mP89Xl8P17WL7dmZZlnaQFgfZ9UiU5QnMjwvCFCUQf/Qn+Qdd+HOrvld7eQAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "pendulum",
                "title": "pendulum",
                "src": "/static/1cefb588d2d23c400454c8695c99d304/5a190/pendulum_5.png",
                "srcSet": ["/static/1cefb588d2d23c400454c8695c99d304/772e8/pendulum_5.png 200w", "/static/1cefb588d2d23c400454c8695c99d304/e17e5/pendulum_5.png 400w", "/static/1cefb588d2d23c400454c8695c99d304/5a190/pendulum_5.png 800w", "/static/1cefb588d2d23c400454c8695c99d304/df56e/pendulum_5.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/b84d5d457c4bb2ee061881b5a2801f2a/df56e/pendulum_4.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADdElEQVQ4y72U609aZxzHz7+0ve32omlHq65dL2qbLpppZxfmpV62Kkw7XKrttrBKuShh9pKm64w2KChqERU4UKhw8BKsA8OlcBDkUuRw7ud5zoJ2S7dkr5rsm8+LJ98n3+f3e/N9EPE9hLxfGEIRQBEACIB4hAjFinsIBALg34UXuCMABAgHACOKvCgKosgdIkAAgFCJVQT+aywAADmIRArLy1n7atJqLTqdb1wuPBrF9zKh3Z10JhWObTxbUpvsepNDP706Oofen5hXP7WoHpuVm394ke0QPmHyT1mwp2bf5BxmXMCCaU+s7A1ml/PlpNM/06H8SKat/uaepEf1Sa+mpnXoROutE43yD03LDxBfvKhyZTTOhGolonGlDWvpraI7kJrZyM6WxX0Um5ONfnr70ecKQ91N/cUfxq98p66Xq+rbhk/OO58ggWh+zIlrnu/8bPTqV2MPPYnt0lqcfxFmHQdCxoVZ+rQ1g+OXBn+tVxjqBvQXr9+p6hg+3XLz2LzjCeKL5kdWXo+hyTE0pXUkDWgsFJmMhA27oTGaDNv95nblx72a6m/Vp29oqnpGJL3amh+nGmTj1XOORwieK/mieSyWx6J5f6ywHs9l0xs5/GUW99JEOp4Kr/ifoQEzGjCh2LQDM3mDz7diDl/IGkm8QmiSZKi3sDTJ0jRN8RTDUTRHUUyx8CaDJ9P5TCq7ny4c5PYS60H05eaqZ90WS4YQiqJZlmdZnmE4kmSLVCHP4GS5TJQOAAAej+daS3N7T8eN/r7u3p7uTulX/Sdbh483yD/43XIPKRYLyVQ8gUfT+3iZKOZK4VQxQJE0QRAQQhR1nTl7/mtpu9k03S9ra/myqXvo0i3DF+23JVOLOmQJNXYOnxvUNvf91JhJmSnMTCw6SaYSBgC4Xe66uvrOzq7Z2dmWa9LGhqY2xZmuOzVXB45NLugQ09Lj71XNM7YHXUOfLWKvbLtr/oyVJhmCKEEI7XbHqVNVly9fkcvkFy7Unj9XK1VIFA9rr48cn1hQVcLdQ7X9d5ukAxLvTnIji4ep7craZYLjuGBwW6n8Racb1elG1WqNakT9m0W1tHV/xqP2bFqRxF7E5jba3EaXf5FlSRFAyEH+LwmC8K8+QCACTgT8YTH+cQFFcNjEdxz49ytvD4AHUACQr1QSVgSO+F9/kj8Bo0fWZnQ2zVIAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "pendulum",
                "title": "pendulum",
                "src": "/static/b84d5d457c4bb2ee061881b5a2801f2a/5a190/pendulum_4.png",
                "srcSet": ["/static/b84d5d457c4bb2ee061881b5a2801f2a/772e8/pendulum_4.png 200w", "/static/b84d5d457c4bb2ee061881b5a2801f2a/e17e5/pendulum_4.png 400w", "/static/b84d5d457c4bb2ee061881b5a2801f2a/5a190/pendulum_4.png 800w", "/static/b84d5d457c4bb2ee061881b5a2801f2a/df56e/pendulum_4.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-3 offset-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/0bed04b761ad96365de46102d54e42d0/df56e/pendulum_8.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAElklEQVQ4yz2T+09aBxTH7x+wJsv2+5Im+6VLlmVr15paoD5AlFZ8tlZaq5GHcAEBsSoKF7g8REQpiKhx7XzUilbX1pa2dtZqp06toCiK+Gxt52aiYkGU511wnckn54dzcs73PHKAOccUTRRXpEotq8lmywmgFMeE8UwYD0oTQCnuyB6DO47SIZzdMQ3MLU4x5fG3aohltzNu1aYWVV/iqZN4agJHhedWJXJU+GO4VYn/g2crExxOWyQZlMUX1xIrDNcqDFcF+nRedVJRTRJPnVRYiedriHxNSoRqYmElnqNK5BxVZCkSFpwzwJxjiiGLK9Om6vWlUMNVzX2aojUHas4o0V0uVOJLtZki/TWRLkugy+CpCSw5jglj2QocW4lfXJ4D7E5LRLkmWWa8KahLq6jPFDVkChszi2uTuSoCX0MU1V5RGK7DDaQSbZqi9abuEaO8Pp0lT1hZdwAW2xgIx7MU8VTJhQIphiZBU8UoqhhFk6Dp8EU6HEOVoCkSFPWIW3UE0Z30Yu0lpjzB7rAC45ZhKoRWNFNb+6rvPlQ2PRA3mCoauoRN3ZC2jVffWd7aV9XyqLKpW2w0VRg6yuvvlYv0JAYcZ52dAN5Oj+YKzj4evIsgSCgUOvQEfAfBMBJGEOTj1urG5mrEHwyHgkg4jITDIQRBuvsNZFG0zW4BJq0juYJzPS8bA0HE5XK53R7Xnntn13V4GFh5P7+4Nuv3Bz37nn2vd9974PZ4AoFwh1lLFkVPz74Fpmxjx8oHhwGfP+T3hdx73qAP2dhcfffXCoIggWDY74sQCEaUu17U5QvPW22TgN1ppUkwt9v5A+O9T4fazUPtT163PRls7R8xmZ7pTc/rfh/rfjrcHgkNt5uH7/WPdKl/ZdHh2MipZuYnmHIcQx6bK/w5H4rKh6IYcgxYGQuq4jhqLKiIIUNRdBmKCkcXyFB0GaZQjWVVxrMVeMfSLDBtnyiQXJQY854Nd97/TXfvkR4y5mXfQIHkWDJ4GarPFSq4HD5NqRLUdfAhHYlVnMKVE9nKhAWnDZh3Wsmi6KYe8fuVjRvXrr948VqlE1y9+GMO5qe8S4kgM6PX1NIsFplbOnq6jQx6YuyZ7ylcLFeduLy6EJmZKkZr24rHR0YpFOrjPnNhyQ3C2W+zMD/QUrAiIbm9Uabnkvt0yjuN0qrb+d+dOkmiY4o0BOfKPGBftNDhGIWRPjT4utHYMDk+mZWHP/HlCTA/R0zO4jPTQFKyhpHdBRcZqsACOuHUyW9IBWieJukoObJtdG0Lb2HeMfCy3z49LVQwv/7qC/z5M1Tsuespp1OxF0qyiVJKLlxC4jLSSzlMHnSFXYlbXvvcNkrfUfrJ7d7d2/X7Aq/GH46ODVpHnjttMwNveiemhhanhv/+8M/g6FPL3B/bG8utD6RUCWZpZT6yMAqEMnSW+QMh78FBIBh+YzFvf9pyezcRBLEsvNnYWvX5XQiCzDj/dKzbDn07JnMNBUL995Kflfe9vq3+V56dvYGJh9s7ux/cSy6Pa9T6cuPju03P+t6Ba2JmcGltwRPc++WJmAZhFpfm/gU3nsrvgnyMxQAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "pendulum",
                "title": "pendulum",
                "src": "/static/0bed04b761ad96365de46102d54e42d0/5a190/pendulum_8.png",
                "srcSet": ["/static/0bed04b761ad96365de46102d54e42d0/772e8/pendulum_8.png 200w", "/static/0bed04b761ad96365de46102d54e42d0/e17e5/pendulum_8.png 400w", "/static/0bed04b761ad96365de46102d54e42d0/5a190/pendulum_8.png 800w", "/static/0bed04b761ad96365de46102d54e42d0/df56e/pendulum_8.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/26cec981445e8867d7cf7a18a7e76241/df56e/pendulum_12.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAEz0lEQVQ4ywHEBDv7APz8/P///6SkpiwtMGJhYWllaVlZXFRVUzk+RB8gIi8xMy0vMCwtLissLSosLSstLissLTg4OT0/Pz5BQQBhYWBhYV84NzhcX2TMx8XEsqqfiH+ainp/g4AfIS0mJiogICEhIiMgISEgISAiISAcHR5hXWTDq5y2npQAISQnIiUnFRYZZWZntquffWZdXUlBU0pDYWNiJR4kIR8hGR0dGyAgGh0eHR0dHRwbHBwcU1FinYiJw6SUAD09PkFBQi0tMC0uMDc2OTgxNEZCQD03OFJBQVBMTisqKxgbGx8kIxweHxoZFxoYFSAeHU1NWD5CUGxkYADi4uv4+PqYmJkVFRogICQdHiNOTUqAZ12WZ1pNSVUvLTAYGxsdISIcHh4nKjApLjQfHx+AgYN9ZluNdmcA5OT4////mZmdDw8UHBwgGxoeOjc5Rjk5Z0xGVk1TPzk4IyYnIiQmIiMkOUNSNTlKHhoaRklOOzU1TUdDANff2M7Pz5SXlD1CPUZKR0ZGRh4eHiUnKywxNDY1OTIsLh8fHiYlJCUlIyUpKWJKQFREQEJBPD85MDc5NQDj6uTk5eTm6eba4tvq7evg4OEwMC8rMTeakI1lYmUrLjZEPz5EPjopKSY7Oz2KfHKBgIGnrLOGhYNXYmUA+v78/////P/89/32////7u7uLy8wVlNQpJude3uANztBMTE1NzY3Jicqa2NegYiRSkVHVElEZ1xPRkZLAMnMy83P0c/P0MrLy8vPz8PExSIjJqiYgaeNgY6NjFNYVCQUDTYtJiIbFp+OfrWtrGUtEVw2JYlkQF09JgApIyI/KCcpLCwmKitIKyk1IyIZHR4+PDdIQj48OjdBRU5OVGk2MC8hHhyDb2GmiHJ2NRZoRjOHYUBPMyUAKSkrPDIyLzQ0KjM0OjUyMzAyKC0uJicmKSssJicnMjpDO0dbGBgbERQVOSslOiYdIQYAKRwWRzAhMSEbAH51ZYuEcYuBcX93aWRaTmZSS11QRm1lWmdeVGJaUF9YTEJAPDU9QzY4OkdGQU1SUExST0tNSlRXUzo9OwCIe2Kfj3OqmX62ooW6inGQWkuac1u8qY+yoYahknehkHaCfXJ7i5N8fnnEwKyyq52ol4e+vrC/v7JwcG8ALisiLSoiLSsjRkA1p3ljgFFCmnRdyLWbv62SsaKIr56ChHtscX6HiIqCx8i2pI9+lmtbsLWvwLy3homMAIh9Z5yPd4l8Z4x/aZJvV2lBL2pbUpV2ZZl5YZuLcqaIaoqDdmZ2iI+VkcPEtpuSio12aouLiYqHhGlrbABBPDKEeGJgWEmom4XEnIOKYVGTfG2zoImolnqbiGymkXKPe2lEKSw2ExlDGB5BHiEyJiktLzIvLjAnJyoAaWNYu62SzsCo6NW/3s251MWy39C56dvCw7KelIFumYhxemVUn3RfgWhjbkxQPQIGLRcbHSElIB8iICAkAJeKdODGpuKvnN2sm+fNuOTAreDBrsi8p456emFRX1g+R1Q5N3dOOXBwc4BscUwBBTEZHB4hJiAfIyAgJABjXlJwXlJqS0VxXFV7cmmCdm1zamJ7WFFUSEZPTFBIQkg2MTAXFBUMDxksFhxJExImGhweISQjIyYhIiW+FsBBb/vJqAAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "pendulum",
                "title": "pendulum",
                "src": "/static/26cec981445e8867d7cf7a18a7e76241/5a190/pendulum_12.png",
                "srcSet": ["/static/26cec981445e8867d7cf7a18a7e76241/772e8/pendulum_12.png 200w", "/static/26cec981445e8867d7cf7a18a7e76241/e17e5/pendulum_12.png 400w", "/static/26cec981445e8867d7cf7a18a7e76241/5a190/pendulum_12.png 800w", "/static/26cec981445e8867d7cf7a18a7e76241/df56e/pendulum_12.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/93d8687a5c260176fcee30aa2fc100c6/df56e/pendulum_11.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAELElEQVQ4y22Ta0ybVRjHT9u1wrDMWiiUFlp6AXqjpbSF0tIblHtpuZR7BUGtbIMiU8SxfWA64gdkiQ5dlMRtJk4UNZvTuBAZQsaM7oMZ2eIlfhHbpAXfTlPoS9+baYHF2z9Pzrffec7z/z8HSNUWicqs1FZq9NUGQ53V5KixOJ1WV5vN1VftPtHvm5qZXf/pZ4IgMAzDcTxx7AuIZGUiWZlYZpSqrIXFNlWRtVRnrzI5mqrcfZ3e4cExb0PX8iefEQQBbe6Ef4dRhHgoIBAXZ+coNObG9oHnhnwnR09MdHV7TeWOOluzy+Rw6+1NUu1XC58iGBb4LRAKbvr9fgiCggkBoUAl19i6J6bc/b6x8TPzb79zb22p0dFRojBUayzNpbY2VdnSx1cJgngQDkMQFAqF/jgQKJToXb3PembetLX0Ody9DpP9+oVzNTXNWpm+VmtpN9V6zI6V6zf2Zib+KWDVmrVai314XF1SodaYdTKNt8HVWe0wqsrrNeaRFs/8K5OBe98TBIGiCIIgsb8JlCgM9b2D5rPTYrlBIlDoCpR9tqqBWmelxuIprzrtaL7yoi/8yzpBELHYLgRBgUAgGAyGQiG/3w/0SnPH06OWY2McsVaQIykTyfuNlkZ9ZZPO6rM3vOF54lWH88b8AkEQOIbBMByJRKLR6Pb2diQSAQOjL7V2P9PXf0yvs4nZ4if1tosvnJwaOn68pnHIVPf55MTdS9M3r8YNg6NRGIYfJBSJROKGnVparXV1Vta7O909lVL1mLN1uLX7/Ijv2+mjHxztuX9pBl7/6P6tr/cMgyBoY2Njc3Nza2srHlXHwm11Q09Bkclkc5o1Zm9Ll7ehxdvS+15Tzc0R18ryyurcuz9+cytuLo7v7OyEw2EE2V8UoGp9Kj1fzRXKRXkqZbHRbKwo15raHK2v1dmvXTj3+sTsYJ51ceGLeGf0P1GlMFlUOoOWnUsTFRxmsFIyuMlpmVTaIyTqoXFPx53R05N1g8sfLv5/zqnywiS+MFlXSrdV0PgiukRGYnHIJDIAwCgQ/XBlNhb6bvfP4N6zcRxHEAw9KJDEziEzWDRxQZJCSUljUxgZ5NQ0CuUQAEDB5d9+6ywRW0V3f02w8X+1C6OJQnZhBNCYbJD82OE8SYqqiERnAnoaKTWNlIDFXN7LXW3nTz2/duduYsPiMBxF9ioGo3GYwsyk5QhShGK2rDCVywepzD1YJhR3GSzO6vbFtfWHMBLDkFji2SgGqI9nUlgcao7gUZ4gJVdEYbDIR/Y7K6Xyfldbk7nqy2txtxEETVyBo+jBzDRGBpXDS87mU9LZyQIxSGGQjzBJFAoAID0zOyMrn07PmJu7GMcSMIbiWILHUBzkC4t4WQV8joTPlQp4ivxcZR5foRSoSiVajrCIJ1JnsUWXL7+f6In+K6q/AI1CfxmMHbFFAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "pendulum",
                "title": "pendulum",
                "src": "/static/93d8687a5c260176fcee30aa2fc100c6/5a190/pendulum_11.png",
                "srcSet": ["/static/93d8687a5c260176fcee30aa2fc100c6/772e8/pendulum_11.png 200w", "/static/93d8687a5c260176fcee30aa2fc100c6/e17e5/pendulum_11.png 400w", "/static/93d8687a5c260176fcee30aa2fc100c6/5a190/pendulum_11.png 800w", "/static/93d8687a5c260176fcee30aa2fc100c6/df56e/pendulum_11.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <div className="col-lg-8 my-5 py-5 border-top">
      <h3>{`3. Launch`}</h3>
      <ul>
        <li parentName="ul">{`A concept demo was prepared for Startup Nights event in Berlin `}</li>
        <li parentName="ul">{`A pilot project testing walking symmetry was prepared for Horus Prosthetics`}</li>
        <li parentName="ul">{`A POGs demo was prepared for prosthetic therapists in Ukraine`}</li>
        <li parentName="ul">{`Various demos were shown to physiotherapy and Parkinson therapists in Spain`}</li>
      </ul>
      <p>{`Esteban participated in networking events, prepared presentations, designed the `}<em parentName="p">{`brand`}</em>{`, other communications (website and blog), videos, and promotion through online and offline events. `}</p>
    </div>
    <div className="row">
      <div className="col-lg-3 offset-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/28c7d3dc67ab2debe7ba4b99f1aa6b09/df56e/pendulum_3.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAEkUlEQVQ4yy2SW1MTBwCF9x90xqZ2oqMdq4hKVXRQAQkmiiBiE5iAEXCsIPe0iGgqKOAFtVqRKDMKeJvWgorWas0mJJDdJHvJ3m+57WZ3k3TG2v6NTpzOnIfz8s35Hg4A3uvx3HPCj13hXy6gz6/SbydjS4/S2PPXC08uXh0fuzzW4hrvPjs8M9T7btQ5crL5QdWea6ai9mrTrbJiQGVhXUIzGSGrC7mslMtJusZ/zHCvfIvfTzy9PHSu77TLMXBl9Afn/JmTffvKbu3avGCvfNbVNLZhLaBgHgXxpKO+NA6qqEeN/JlG3n9IEwt+//DkQ25qaHR41NHrGuzpGnF2NR49NnjoYOd6456iwv0FmwB98YXqe6EFFjToTRqc1wKvNBT8oNPv3/3eNnjJ1ddZa2064WjuOtXR0nqiod5+/pSleNO6FZ+tWPWlEcDDHpYI8OQSRwR4BtIUSlPIj1nu7Yx7m6l2q8W2ZWd5fW2Ns6e7u6PzSK3t3tAe674C4+dG4xcrAQbzKVJEiSFKDFETmJ7E1Rj6b46fvHunqHR/xSH79pLypnqb68yZfqfzSG39zQFTvblwlWHVaoMB0NlllQqoZEDnYJ0J6nRQJfz/JPGf3RNFFuvOupaNJSarvann9MDx9vaqWuu4y2IpLTQYjCuNq4E04lEjHg0BNdSr4z4d96qY7285+uzxTGNd08l6x3eWmolG203bt8Nm0+1qC9xfbd+9tXrDRse2HYCGgirmVTGvhoIa5tUxr454sjKp372smrYmDuwWTcV8ZQlrLovVHRStNUxlxf2izXNr1hCFm4FPzn6NXtKogEYtaUJYF0KZHJ+dGOFKCo5u29TSfHzw/Mi5oYv2wzVtrY6B/l5Hk/1AVVWxwQBoCSyfJK6n8smkopkklskJuTujXOkWc1FBz+0Hj2BmPoi2tjZf/+kqIlCzF4afuM5/vfYrICWEZTGsSJGUEJLFcEoIpzg4pVC5qStCZfHhHd8Muqfnw+QbCOnsaL9z+0aII2ebW6CK/bvWrQcoBCQjHgZbJCMgGQEpxEuFQZaP5NyXZNP2I6UlthNtl2bnRidnB/o6p6envBjS2df72+uXZXvNABnx0KiXQkAO99OIj0EXOcwvipjsvvJrabGlvLzCZLY2HOvoHnBfvzH35L57+n5r26nxycndey0AjXoZzEejXhr1sbifwfws5pcFxPvy6a7S8v6zP07NPHr4/NVLHwy+/QN7P3dt7KKt4ejBuoa95po8zOGLn/g8zGJ+BguIFCQyMOR7ExcwOU4mY0RcjKbj5F8KIwsYjQQoJMBFgwCNgjSaX2bQvDOD+XkiyBPL2RSRSRGKhMoSrsRwNUHoMp1RWC1Jp2OkLEbTUjT/beZ/4UUWD3DRJZ5Y5onlFI+w2JJAQjwBCVQozkTSIqYniDgTYZBlMhQQSRiIU8EYFZRoKEZDApEvioRqyWiSjwgkzBMQHw2KVEgiIUVA0xIWZ8I8DpGhQIJBAZkLxRkoxkBJPpxg4QQXlkUkK5OKhAkkLNEhiYIFCpaYUIpHUjwSZxEWzS/HqPB/YiXb8bs8jvAAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "pendulum",
                "title": "pendulum",
                "src": "/static/28c7d3dc67ab2debe7ba4b99f1aa6b09/5a190/pendulum_3.png",
                "srcSet": ["/static/28c7d3dc67ab2debe7ba4b99f1aa6b09/772e8/pendulum_3.png 200w", "/static/28c7d3dc67ab2debe7ba4b99f1aa6b09/e17e5/pendulum_3.png 400w", "/static/28c7d3dc67ab2debe7ba4b99f1aa6b09/5a190/pendulum_3.png 800w", "/static/28c7d3dc67ab2debe7ba4b99f1aa6b09/df56e/pendulum_3.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/b0b6615d790cc1831b433fed036e0c21/df56e/pendulum_7.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAEz0lEQVQ4ywHEBDv7AK+gjt7h1svNwba4rsXFudPTxc3NwcjJvcHCtbm2qb69sNTUy9DSz72/u8vJw93b1eHg2ufm4Ono4+rp5ADEuqnr8OPP0MSVdF/Buaq+vruysra2q6DCsqLGxri/va/U1szYxsLUw77i4dvf3Nbm5N7n5+Hg39rx7ucA1s68oJ6WR0ZKcVtOpKObn4R4r6qkrZ+UvZ6LxMa5vbmt0dXL1rKuxqOez9HL5uHa6ube6OXd3dfQ6+fhAMi2oohuWx0fJxseJ3R0dcelkLayrGhrblJQV1xja7u4q8rHvObj3pB9dHFLOrCnofHt5d/b0+fh2ePf2ADNyrqfi3QwKSwnJi2biH7i4eOgnJ0tLzhXWmghLUGQkIrEw7fq5+DGl4Cxa068rqXv6eHX0cnY1c/e29YA1tC+fHdtFxklJR4km39w4OPsb1xZMzA1Y2RwHCo/nYpz3Mmwr7CutoNkvpB35uHZ593U7eTcsaafppySANLMuoF7cR4lMiskJYJuZqOpt2FTUjgzOVlcaBUiN6+YeLugfiAhKUU2MTIqKX16eO7k2+Pf17uJeYJWRwC3q5ihlYWWj4ObkoKJgXRua2KJgXenp6WFhYRTOjGVh3qWmJYgHyMpKjAWFh1QTE3t5NqzrKaOX05zST0Ao5aGs6eTt66btK6em5eJk5CEtLOrwMK4q56NhVdGel5Wf4WJIR8iNDE0HhwhSkhK0cvFQjk6JBoZMiYkAGhjWpqThJaShJKRhYuMhJ6gm6inn4yJg6Klo5JwaXRLPkw8NCUlKDQvMh8eI0FBQ5WTkSgmKionKy0qLQB/enJ5d2yTlImanpqrtre+v7Vta2QcISVkXWJyQEJXOjMvJyMrKy42NDYiICZMR0dsaGYgHyM2MzYyMDMAtrSoo6adr62jnJiQjpmcfH9+YFBHWkc6MTAwMysqgVxOZExDGhwiLiwwFxceRUFBaWZnEBEYKiouJyYrAKqpnrGvqaeRhZZvWmtlZJmhpoR7d1hHQjw9PBsgIB8XFVZFOndtY1hPTk5IR3h0bqykmXZtZHxxZ3xwagCOgHCMgXSdk5BbTkswMDhxeIF+goVOUVV4YlqEXlIOCAY7OEGmk5O2jnehfmqvpZfZxq+slYawmovfzb0AZ15OYlxLcWZbGx4mChIeEhUgV1ZUVlVQQjAvpHxpXFVLODBEs4iEt4hsi2RSl4uAraWlu41vn39ql5KXADYwLHdoVpNkTU02MA8WIA0THz5AQTI0NAAAAEhCPIqHd1hNSsaUe6d3Y4xqWMG+usertMePb6R8bXt3hwBrSTqEdme4g2llRz0JEBoHDhkvMTZSXGMPEBAJCwxmYVRbUUuKYUyIY05+dG+YkZqxfYqmd2SAa0Z2dU8Ad09AiXVnk2tTppWFdnVxSDY1EBEcVG9/OUdOBwMCJCYkLi4xUTkxVDsuIhwgJyAgQjg6VEE+SD0xYVhQAHBJPLOfja+klc/HuK2upmVnax4iLC89Sn2hskROVDpBRiAiJikfIiweHBEMDiAbHCwlKigmLiopLyspLwB1VEGqoJKtpZjPxrijqKNkjZpDU10pNkFhfI5+nrE+TFAkKSopHiItJCgVEhMUEBM1JyNFODkvLTApKC61HDEMyiDy8gAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "pendulum",
                "title": "pendulum",
                "src": "/static/b0b6615d790cc1831b433fed036e0c21/5a190/pendulum_7.png",
                "srcSet": ["/static/b0b6615d790cc1831b433fed036e0c21/772e8/pendulum_7.png 200w", "/static/b0b6615d790cc1831b433fed036e0c21/e17e5/pendulum_7.png 400w", "/static/b0b6615d790cc1831b433fed036e0c21/5a190/pendulum_7.png 800w", "/static/b0b6615d790cc1831b433fed036e0c21/df56e/pendulum_7.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/22ef9118ed84a2d2cc1eb66ff787a51f/df56e/pendulum_2.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAEz0lEQVQ4ywHEBDv7AKGbrKagrJaRnIR5gM64ktbEnM2wlbWZeaeYgm+Bo0pjk352rnx0pGJpu35Ro6pkgrmfa3mMlUJfhnhluQCVl6qJip9hZmxman6/qpDi0qjp26ju2pvhzZ7rzr+woK5yZLRoYaJpcqjGX6jkgZmRfHw+X4eJg8jCkf8AinyRhnuWfniEZl2OcWd/mpWRu4KRn4yXxbKf0caJxKmplX/PZGWKwLeF8pp+pHZ9hHB5aYeIrJ7KupDfALObkIpki6BriMB7jIxyhmVZY3xGjnNXdKV/kXKQgJabm5Gek16PY4mkcIl4eGplYtioXYWgd7Sre9i3pQCXfnObXnewcoSubHp0UGJdVlWbhXGYlVyckF6BomCToYPIs5iDj38qVIqRaGy8lkOZm2BzpmOUrHzXxscAkItvnlFlv294vm16hFhpZ1tduXWAkZdcnK1efcBmg5p3hXyASVSHX3aTvn9oxJdYrJB4g6RwlrCQoqaiAJ6Cc41CVqRUWbNfaldIU2pkWLNScYhTPFRFKFWCR05rbkRXbkFQd357i1EzKpuPU25eNhwnJnVgWJltfwCYiJ1pRGZlPkphTFA+QUddYUvCWnO9bE+MZU9cc1UoS3I4UHE2U4OTi5OFYG+KdlaGYzEWDhiaemecfnMAhHmKSjpeT1t8UkxbP0Q+eG9fv2V6umNR6YOHwqVzqKKdgHh+XUpatIWQaVtzd1xPlnhWFRMdODc1SUtQAJt+lJNjirN+nKx1mIdrYn9wVysZHlApNdpgfMGNg+7Y1sqrr3VZZZ9hX1tIXXpZSU43HQAACw4MFjYwPACVeomdXXquaXuUW3aCUl5eUk8QEhhpQE3Yr5mgeWHUvcW0oLFWXYKlo5tNSFd7W0dUMScXGR9WUVdGP0sAgYCAS0RFMU5RPlRZPj5LIiEwIyEtOi47Wk5QVktOrpGXtKG0YWaOnZCJGRsignRGZEk9QDA1gFRSXzs9AKiAiHZLYEZQcaJQcGA8UR8kLiYiMCMdKS8rNx4bKpyIksGrvG1qgpd9cSQfKIFYSYFWTWNMTVY9Pkk0MQCGYGp5LkVuTGenR2NAKjkXGiYeGCcsJjctLTJ0aXDKtsK9p7eohYOPaWYqIy2Vakd/Y1UJCRIDBQ0UFR4Am3aAnkBPh2l4XU5hIB4qGxkmKCAraGuAhIKJopGMgnN3gXJ7pIuPU0JGGhYbomtTjltMMSYrEg4SDAkPAL23t7uura2iny8yPyAeLSckMikjL1RMT6mgmtjPxcrAtr21rL60s6Samm9rZndiWygVGGFMSS4kJwYGDAC/vr7Aubi1sq8/PEcBAA0AAAsIBhUCABMnJTLVzcLl2tDf1szUycbLv7y3tKlQTk8AAAgREBgUERcPDRMAvsDCurq8zs7MiYmJJSYvbm1woZqTbmlrRD9J7OLU2M/H2M7Ey7+4s6unrK2mb29tLyYuVz9DCwgNCQoQAMDCxb6+wc7P0GZnblJRV+Ph1//96G9pa2BbX9/Vx761r762r8zEvMC6srSjmHxmXiwdILJnVGlAOA0NEwC5vsO5u8C+w8eCf46GeYK/ubfs2chVSlB5a3XYy8DXy8G4raeji4Sxe2y3cF2BVEggFhtyWFSSiYiXkJFW9CbdYPcOJAAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "pendulum",
                "title": "pendulum",
                "src": "/static/22ef9118ed84a2d2cc1eb66ff787a51f/5a190/pendulum_2.png",
                "srcSet": ["/static/22ef9118ed84a2d2cc1eb66ff787a51f/772e8/pendulum_2.png 200w", "/static/22ef9118ed84a2d2cc1eb66ff787a51f/e17e5/pendulum_2.png 400w", "/static/22ef9118ed84a2d2cc1eb66ff787a51f/5a190/pendulum_2.png 800w", "/static/22ef9118ed84a2d2cc1eb66ff787a51f/df56e/pendulum_2.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      